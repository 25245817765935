import React, { useState } from "react"
import clsx from "clsx"
import {
  Button,
  Centered1200Wrapper,
  Errors,
  PageTitle,
} from "../../components"
import Checkbox from "@material-ui/core/Checkbox"
import {
  useAgreedToTerms,
} from "../../services/group"
import {

  acceptTermsAndGeneratePhoenix
} from "../../../../services/quotingServiceApi"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  checkbox: {
    color: "#000 !important",
    paddingLeft: "0px",
  },
  checkboxLabel: {
    fontSize: "15px",
    fontFamily: "tenon, sans-serif",
    color: "#000 !important",
    "& a": {
      color: "#000 !important",
    },
  },
  warningMessage: {
    marginTop: "18px",
    color: "red",
    fontSize: "14px"
  }
})

function SetTenancyDetailsSignedTerms(){
  const sessionStorageTenancyDetailsStr =  sessionStorage.getItem(
    "theBunch-tenancyDetails"
  )

  const sessionStorageTenancyDetails = sessionStorageTenancyDetailsStr && JSON.parse(sessionStorageTenancyDetailsStr)

  if (sessionStorageTenancyDetails){
    sessionStorageTenancyDetails.SignedTerms = true;

    sessionStorage.setItem(
      "theBunch-tenancyDetails",
      JSON.stringify(sessionStorageTenancyDetails)
    )
  }
}

export default function ContractOverviewContainer({
  primary,
      onContinue,
      group,
      lead,
      tenancyInformation,
}) {
  const classes = useStyles()

  React.useEffect(() => {
    document.title = "Bunch - Details"
  }, [])

  const entered =
    tenancyInformation.startDate &&
    parseInt(tenancyInformation.startDate.slice(0, 4)) >= 2020
  const [submitting, setSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState(null)
  const agreedToTerms = useAgreedToTerms()
  const year = new Date().getFullYear()
  const [localErrors, setLocalErrors] = React.useState(null)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [agreed, setAgreed] = useState(false)
  const [hasAgreedToTerms, setHasAgreedToTerms] = React.useState(lead.agreedToTermsAt !== null)
  const [continueButtondisabled, setContinueButtonDisabled] = React.useState(false)


  const [showManualAgencyEntry, setShowManualAgencyEntry] = React.useState(
    tenancyInformation.lettingAgencyName ? true : false
  )

  return (
      <Centered1200Wrapper>
        <div style={{ padding: "0 20px" }}>
          <PageTitle
            style={{ textAlign: "left" }}
          >
            <div className="quotingTool-pageTitle">Contract Overview</div>
          </PageTitle>
          <div
            style={{
              marginTop: "20px",
              padding: "0 20px",
            }}
            className="quotingTool-pageDescription"
          >
            Thank you for choosing a Bunch package. 
            By clicking Continue, you are agreeing to sign up for our services and accept our <b>Terms & Conditions</b>. 
            Here’s what happens next:
          </div>
          <div
            style={{
              marginTop: "20px",
              padding: "0 20px",
            }}
            className="quotingTool-pageDescription"
          >
            <li>You will enter your payment details on a secure page in a new tab</li>
            <li>We will contact your service providers and begin setting up your utilities</li>
            {group.numberOfBillPayers === 1 && group.primaryLeadId === lead.id ? <li>If you have selected multiple bill payers, you will need to invite your housemates</li> : null}
            <li>You will have a <b>14-day cooling-off period</b></li>
            <li>By proceeding, you confirm that you agree to Bunch's <b>Terms & Conditions</b> and <b>Privacy Policy</b></li>

          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 40,
            marginBottom: 20,
            padding: "0 20px",
          }}
        >
          <Checkbox
            color="primary"
            id="agree"
            name="agree"
            disabled={hasAgreedToTerms}
            checked={agreed || hasAgreedToTerms}
            value={agreed || lead.agreedToTerms}
            onChange={e => {
              setAgreed(e.target.checked)
            }}
            className={classes.checkbox}
          />
          <div
            htmlFor="agree"
            style={{
              fontSize: "15px",
              fontFamily: "tenon, sans-serif",
              color: "#000",
            }}
            className={classes.checkboxLabel}
          >
            I agree to Bunch{" "}
            <a
              href="https://www.the-bunch.co.uk/terms-conditions"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#000", textDecorationLine: 'underline' }}
            >
              terms
            </a>{" "}
            and{" "}
            <a
              href="https://www.the-bunch.co.uk/privacy-policy"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#000", textDecorationLine: 'underline' }}
            >
              privacy policy.
            </a>
          </div>

        </div>
        <div
          style={{
            marginTop: "20px",
            padding: "0 20px",
          }}
        >
          <p>On clicking continue we will commence switching your services over, this will lock in these details.
            If you require any changes to these details after this step, please get in touch with our customer service team.</p>
          <Button
            className={clsx(
              "SignUp-Step-02-Details",
              "quotingTool-submitButton"
            )}
            disabled={(!agreed && !hasAgreedToTerms) || continueButtondisabled}
            fullWidth
            onClick={() => {
              if (submitting) {
                return false
              }

              setSubmitAttempted(true)

              // validate everything exists!

              const newErrors = []
              setLocalErrors(null)
              let localErrors = []
              let hasError = false
              Object.values(newErrors).forEach(value => {
                if (value.error) {
                  localErrors.push(value.helperText)
                  hasError = true
                }
              })

              if (hasError) {
                setLocalErrors([
                  "You need to correct the issues above before continuing",
                ])
                return false
              }

              if (!agreed && !hasAgreedToTerms) {
                setLocalErrors([
                  "You need to agree to the terms and conditions before continuing",
                ])
                return false
              }

              setSubmitting(true)
              setErrors(null)

              if ((hasAgreedToTerms && lead.phoenixId)) {
                acceptTermsAndGeneratePhoenix(lead.id).then(r => {
                  if (r.isSuccess) {
                    setSubmitting(false)
                    SetTenancyDetailsSignedTerms()
                        //window.location.href = r.value;
                    onContinue()
                  }
                  else {
                    setSubmitting(false)
                    console.log(r)
                    setErrors(r.error)
                  }
                })
                onContinue()
              } else {
               
                acceptTermsAndGeneratePhoenix(lead.id).then(r => {
                  if (r.isSuccess) {
                    setSubmitting(false)
                    SetTenancyDetailsSignedTerms()
                            //window.location.href = r.value;
                    onContinue()
                  } else {
                    setSubmitting(false)
                    console.log(r)
                    setErrors(r.error)
                  }
                })
            }
            }}
          >
            {submitting ? "Processing..." : "Continue"}
          </Button>
          <Errors>{localErrors || errors}</Errors>
        </div>
      </Centered1200Wrapper>
  )
}
