import React from "react"
import { useQuery } from "react-query"
import { useGet, usePost } from "./api"
import { subscribe, publish, unsubscribe } from "../pubSub"

export function useGetAgencies(searchTerm) {
  const get = useGet()

  return useQuery(
    `locations-${searchTerm}`,
    () => {
      return get(
        `/leads/Lead/agencies?searchTerm=${encodeURIComponent(searchTerm)}`
      )
    },
    {
      enabled: false,
    }
  )
}

export function useGetUniversities(city) {
  const get = useGet()

  return useQuery(`universities-${city}`, () => {
    return get(`/leads/Lead/universities?city=${city}`)
  })
}

export function useGetGroup(groupId) {
  const get = useGet()

  const query = useQuery(`getGroup-${groupId}`, () => {
    return get(`/leads/Lead/group/${groupId}`)
  })

  React.useEffect(() => {
    subscribe("group.update", query.refetch)
    return () => unsubscribe("group.update", query.refetch)
  })

  return query
}

export function useGetLead(leadId) {
  const get = useGet()

  const query = useQuery(`getLead-${leadId}`, () => {
    return get(`/leads/Lead/lead/${leadId}`)
  })

  React.useEffect(() => {
    subscribe("lead.update", query.refetch)
    return () => unsubscribe("lead.update", query.refetch)
  })

  return query
}

export function useGetPaid(groupId) {
  const get = useGet()

  const query = useQuery(`getPaid-${groupId}`, () => {
    return get(`/payments/Groups/${groupId}/complete`)
  })

  React.useEffect(() => {
    subscribe("paid.update", query.refetch)
    return () => unsubscribe("paid.update", query.refetch)
  })

  return query
}

export function useSetTenancyInformation() {
  const post = usePost(`/leads/lead/tenancy`)

  const postRef = React.useRef(
    ({
      groupId,
      startDate,
      endDate,
      lettingAgencyName,
      agencyId,
      isTenancyStartDateEstimate,
      isTenancyEndDateEstimate,
      address,
    }) => {
      return post({
        groupId,
        startDate,
        endDate,
        lettingAgencyName,
        agencyId,
        isTenancyStartDateEstimate,
        isTenancyEndDateEstimate,
        address: {
          ...address,
        },
      }).then(r => {
        publish("group.update")
        publish("lead.update")

        return r
      })
    }
  )

  return postRef.current
}

export function useAgreedToTerms() {
  const post = usePost(`/leads/lead/agreed-to-terms`)

  const postRef = React.useRef(({ leadId }) => {
    return post({
      leadId,
    }).then(r => {
      return r
    })
  })

  return postRef.current
}

export function useSelectedQueueJump() {
  const post = usePost(`/leads/lead/selected-queue-jump`)

  const postRef = React.useRef(({ leadId }) => {
    return post({
      leadId,
    }).then(r => {
      return r
    })
  })

  return postRef.current
}

export function useSetSignupLater() {
  const post = usePost("/leads/lead/signup-later")

  const postRef = React.useRef(({ groupId }) => {
    return post({
      groupId,
    }).then(r => {
      publish("group.update")
      publish("lead.update")
      return r
    })
  })

  return postRef.current
}

export function useSetPersonalInformation() {
  const post = usePost(`/leads/lead/personal`)

  const postRef = React.useRef(
    ({
      leadId,
      groupId,
      startDate,
      endDate,
      lettingAgencyName,
      agencyId,
      isTenancyStartDateEstimate,
      isTenancyEndDateEstimate,
      address,
      dateOfBirth,
      graduationYear,
      student,
      moveInDate,
      moveInDateEstimate,
      firstName,
      lastName,
      phoneNumber,
      email,
      university,
      isVulnerablePerson,
      psrCategory,
      psrAuthorisedContactName,
      psrAuthorisedContactEmail,
      psrAuthorisedContactDateOfBirth,
      agreedToTerms
    }) => {
      return post({
        leadId,
        groupId,
        startDate,
        endDate,
        lettingAgencyName,
        agencyId,
        isTenancyStartDateEstimate,
        isTenancyEndDateEstimate,
        address: {
          ...address,
        },
        dateOfBirth,
        graduationYear,
        student,
        moveInDate,
        moveInDateEstimate,
        firstName,
        lastName,
        phoneNumber,
        email,
        university,
        isVulnerablePerson,
        psrCategory,
        psrAuthorisedContactName,
        psrAuthorisedContactEmail,
        psrAuthorisedContactDateOfBirth,
        agreedToTerms
      }).then(r => {
        publish("group.update")
        publish("lead.update")
        return r
      })
    }
  )

  return postRef.current
}

export function useSendInviteEmails() {
  const post = usePost(`/leads/lead/emails`)

  const postRef = React.useRef(({ groupId, invites }) => {
    return post({
      groupId,
      invites,
    }).then(r => {
      publish("group.update")
      publish("lead.update")
      return r
    })
  })

  return postRef.current
}

export function useAddNewHousemate() {
  const post = usePost(`/leads/lead/new-housemate`)

  const postRef = React.useRef(({ groupId, invites }) => {
    return post({
      groupId,
      invites,
    }).then(r => {
      publish("group.update")
      publish("lead.update")
      return r
    })
  })

  return postRef.current
}

export function useMarkAsPaid(leadId) {
  const post = usePost(`/leads/lead/send-complete-email/${leadId}`)

  const postRef = React.useRef(() => {
    return post({}).then(r => {
      publish("group.update")
      publish("lead.update")
      return r
    })
  })

  return postRef.current
}
