import customError from "../util/customError"
import * as Sentry from "@sentry/react"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"

/* ----------- EXCEPTIONS ---------- */
export const UnableToConnectException = customError(
  "UnableToConnectException",
  "Unable to connect to the server."
)
export const ServerErrorException = customError(
  "ServerErrorException",
  "There was an error on the server."
)
export const NotFoundException = customError(
  "NotFoundException",
  "Endpoint not found."
)
export const AuthenticationException = customError(
  "AuthenticationException",
  "User is not authenticated."
)
export const BadRequestException = customError(
  "BadRequestException",
  "Bad request sent."
)
export const TimeoutException = customError(
  "TimeoutException",
  "The request timed out."
)

/* ----------- PRIVATE FUNCTIONS ---------- */
const ADDRESS = process.env.GATSBY_QUOTING_URL

function convertError(data) {
  if (data.errors && data.errors[0]) {
    if (Array.isArray(data.errors)) {
      return data.errors[0].reason || data.errors[0]
    } else if (typeof data.errors === "object") {
      const firstKey = Object.keys(data.errors)[0]
      return data.errors[firstKey]
    }
  }

  return "An error occurred"
}

function getAuthHeader() {
  const existingTokensStr = localStorage.getItem("theBunch-tenantTokens")

  if (existingTokensStr) {
    const existingTokens = JSON.parse(existingTokensStr)
    return "Bearer " + existingTokens.accessToken
  }

  const existingMeterReadingOnlyTokensStr = localStorage.getItem(
    "theBunch-meterReadingOnlyTokens"
  )

  if (existingMeterReadingOnlyTokensStr) {
    const existingMeterReadingOnlyTokens = JSON.parse(
      existingMeterReadingOnlyTokensStr
    )
    return "Bearer " + existingMeterReadingOnlyTokens.accessToken
  }

  const existingUpdatePaymentDetailsOnlyTokensStr = localStorage.getItem(
    "theBunch-updatePaymentDetailsOnlyTokens"
  )

  if (existingUpdatePaymentDetailsOnlyTokensStr) {
    const existingUpdatePaymentDetailsOnlyTokens = JSON.parse(
      existingUpdatePaymentDetailsOnlyTokensStr
    )
    return "Bearer " + existingUpdatePaymentDetailsOnlyTokens.accessToken
  }
}

async function request(path, settings) {
  let response
  try {
    response = await fetch(`${ADDRESS}${path}`, settings)
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
    throw new UnableToConnectException()
  }

  if (response.status === 404) throw new NotFoundException()
  if (response.status === 500) throw new ServerErrorException()
  if (response.status === 401) throw new AuthenticationException()
  if (response.status === 403) throw new AuthenticationException()
  if (response.status === 400)
    throw new BadRequestException(convertError(await response.json()))

  return response
}

export async function get(path, newAccessToken) {
  return await request(path, {
    headers: {
      Authorization: newAccessToken ? `Bearer ${newAccessToken}` : getAuthHeader(),
    },
  })
}

export async function del(path, body) {
  return await request(path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  })
}

export async function post(path, body) {
  return await request(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  })
}

export async function put(path, body) {
  return await request(path, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  })
}

export async function postFiles(path, files, body) {
  const formData = new FormData()

  files.forEach((file, index) => {
    formData.append(`file${index + 1}`, file)
  })

  Object.keys(body)
    .filter(key => body[key])
    .forEach(key => {
      if (Array.isArray(body[key])) {
        // If the value is an array, append each item
        body[key].forEach((item, idx) => {
          formData.append(`${key}[]`, item)
        })
      } else {
        // If it's not an array, append the value directly
        formData.append(key, body[key])
      }
    })

  return await request(path, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: getAuthHeader(),
    },
    body: formData,
  })
}

/* ----------- PUBLIC FUNCTIONS ----------- */
export async function lookupAddresses(postCode) {
  const response = await post("/quoting/addresses/lookup-address", {
    postCode,
  })
  return await response.json()
}

export async function convertPhoenixLead(lead) {
  const response = await post(`/phoenix/convert-lead/${lead}`)
  return await response.json()
  }

  export async function createPhoenixSetup(lead) {
    const response = await post(`/customer/payments/${lead}/create-phoenix-setup`)
    return await response.json()
}

export async function useProccessUnsuccessfulPayment(checkoutId) {
  const response = await post(`/payments/customer/${checkoutId}/unsuccessful-checkout`)
  return await response.json()
}

export async function useProccessSuccessfulPayment(checkoutId) {
  const response = await post(`/payments/customer/${checkoutId}/successful-checkout`)
  return await response.json()
}

export async function processPayment(checkoutId, stripeCustomerId, tenantId) {
  const body = { stripeCustomerId, tenantId };
  const response = await post(`/payments/customer/${checkoutId}/process-checkout`, body);
  return await response.json();
}

export async function acceptTermsAndGeneratePhoenix(lead) {
  const response = await post(`/leads/lead/process-lead-redirect-to-payment/${lead}`)
  return await response.json()
}

export async function GetPaymentSchedule(lead) {
  const response = await get(`/leads/lead/get-payment-schedule/${lead}`)
  return await response.json()
}

export async function generatePhoenixSession(lead) {
  const response = await get(`/phoenix/generate-checkout-session/${lead}`)
  return await response.json()
}