import React from "react"
import { navigate } from "gatsby"

import {
  Centered1200Wrapper,
  TextWithSubtext,
  Button,
  PageTitle,
} from "../components"

import PageWrapper from "../../../components/PageWrapper"

function ToolNotFoundPage({ location }) {

  return (
    <PageWrapper
      location={location}
      quoteViewMode={true}
      backgroundColor="#FAFAFA"
    >
    <Centered1200Wrapper>
                    <div style={{ padding: "0 20px" }}>
                      <PageTitle
                        style={{ textAlign: "left" }}
                      >
                        <div className="quotingTool-pageTitle">Page Not Found</div>
                      </PageTitle>
                      <div
                        style={{
                          fontWeight: "400",
                          color: "#000",
                          opacity: "0.54",
                          marginBottom: 20,
                        }}
                        className="quotingTool-pageDescription"
                      >
                        <p>This Quote Tool version was not found. Click below to continue using the standard residential tool.</p>
                      </div>
                    </div>

                    <Button
                    fullWidth
                                onClick={() => navigate(
                                                    "/tenants/app/residential/signup"
                                                  )
                                          }
                      
                                className="getquote-button"
                
                              >
                                Get Quote
                              </Button>
                    </Centered1200Wrapper>                     
    </PageWrapper>
  )
}

export default ToolNotFoundPage
