import React, { useState } from "react"
import clsx from "clsx"
import { DateTime } from "luxon"
import { navigate } from "gatsby"
import {
  AddressLookup,
  FormLine,
  InputSection,
  Dropdown,
  Button,
  Centered1200Wrapper,
  TextWithSubtext,
  Errors,
  FormLabels,
  PageTitle,
} from "../../components"
import { PSROptions } from "../../../../constants"
import Checkbox from "@material-ui/core/Checkbox"
import DropdownWithMultipleSelection from "../components/DropdownWithMultipleSelection"
import {
  useGetAgencies,
  useGetUniversities,
  useSetPersonalInformation,
  useAgreedToTerms,
} from "../../services/group"
import {
  convertPhoenixLead,
  generatePhoenixSession,
  acceptTermsAndGeneratePhoenix
} from "../../../../services/quotingServiceApi"
import { makeStyles } from "@material-ui/core/styles"

import {
  validateName,
  validateEmail,
  validateMobilePhone,
  validateMaxLength,
} from "../../services/errorValidation"
import { validateEnergySelected } from "../../services/errorValidation"


const useStyles = makeStyles({
  checkbox: {
    color: "#000 !important",
    paddingLeft: "0px",
  },
  checkboxLabel: {
    fontSize: "15px",
    fontFamily: "tenon, sans-serif",
    color: "#000 !important",
    "& a": {
      color: "#000 !important",
    },
  },
  warningMessage: {
    marginTop: "18px",
    color: "red",
    fontSize: "14px"
  }
})

export default function PersonalFormContainer({
  city,
  lead,
  group,
  onContinue,
  tenancyInformation,
  setTenancyInformation,
  personalInformation,
  setPersonalInformation,
  primary,
  skipPostPersonalInformation,
  isHomeownerOrRenter,
  hasBroadband,
}) {
  const classes = useStyles()

  React.useEffect(() => {
    document.title = "Bunch - Personal Information"
  }, [])

  const entered =
    tenancyInformation.startDate &&
    parseInt(tenancyInformation.startDate.slice(0, 4)) >= 2020
  const [submitting, setSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState(null)
  const postPersonalInformation = useSetPersonalInformation()
  const agreedToTerms = useAgreedToTerms()
  const year = new Date().getFullYear()
  const [localErrors, setLocalErrors] = React.useState(null)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [hasAgreedToTerms, setHasAgreedToTerms] = React.useState(lead.agreedToTermsAt !== null)

  const [showManualAgencyEntry, setShowManualAgencyEntry] = React.useState(
    tenancyInformation.lettingAgencyName ? true : false
  )

  const universitiesQuery = useGetUniversities(city)

  const years = Array.from(new Array(7), (val, index) => index + year)
  const graduationEntered =
    personalInformation.studentBool && personalInformation.graduationYear === ""
      ? false
      : true
  const [
    showManualUniversityEntry,
    setShowManualUniversityEntry,
  ] = React.useState(false)

  const [validationErrors, setValidationErrors] = React.useState({
    address: {
      error: false,
      helperText: "",
    },
    tenancyStart: {
      error: false,
      helperText: "",
    },
    tenancyEnd: {
      error: false,
      helperText: "",
    },
    agencyId: {
      error: false,
      helperText: "",
    },
    lettingAgencyName: {
      error: false,
      helperText: "",
    },
    firstName: {
      error: false,
      helperText: "",
    },
    lastName: {
      error: false,
      helperText: "",
    },
    email: {
      error: false,
      helperText: "",
    },
    number: {
      error: false,
      helperText: "",
    },
    dob: {
      error: false,
      helperText: "",
    },
    moveIn: {
      error: false,
      helperText: "",
    },
    graduationEntered: {
      error: false,
      helperText: "",
    },
    university: {
      error: false,
      helperText: "",
    },
    psrCategory: {
      error: false,
      helperText: "",
    },
    psrAuthorisedContactName: {
      error: false,
      helperText: "",
    },
    psrAuthorisedContactEmail: {
      error: false,
      helperText: "",
    },
    psrAuthorisedContactDateOfBirth: {
      error: false,
      helperText: "",
    }
  })

  const moveInDateWarningMessage = "IMPORTANT: By choosing this date, you'll be on supply during your cooling off period, which means you'll be liable for any energy and broadband cancellation or usage charges during this time, even if you are within the 14 day cooling off period. If you don't want this, please select a date at least 15 days away."

  function checkMoveInDate(value) {
    if (value != undefined) {
      var date = new Date()
      date.setDate(date.getDate() + 14)
      return (new Date(value) < new Date(date)) && new Date(value) >= new Date('1900-01-01')
    }
  }

  const validateFieldsOnSubmit = newErrors => {
    if (
      primary &&
      (!tenancyInformation.address ||
        !tenancyInformation.address.addressLine1 ||
        !tenancyInformation.address.postCode ||
        !tenancyInformation.address.city)
    ) {
      newErrors.address = { error: true, helperText: "Address is required" }
    } else {
      newErrors.address = { error: false }
    }

    if (tenancyInformation.startDate === null) {
      newErrors.tenancyStart = {
        error: true,
        helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"
          } start date is required`,
      }
    }

    if (tenancyInformation.endDate === null) {
      newErrors.tenancyEnd = {
        error: true,
        helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"
          } end date is required`,
      }
    }

    if (!personalInformation.firstName) {
      newErrors.firstName = {
        error: true,
        helperText: "First name is required",
      }
    }

    if (!personalInformation.lastName) {
      newErrors.lastName = { error: true, helperText: "Last name is required" }
    }

    if (
      personalInformation.firstName &&
      !validateName(personalInformation.firstName)
    ) {
      newErrors.firstName = {
        error: true,
        helperText: "Please enter a valid name",
      }
    }

    if (
      personalInformation.lastName &&
      !validateName(personalInformation.lastName)
    ) {
      newErrors.lastName = {
        error: true,
        helperText: "Please enter a valid name",
      }
    }

    if (personalInformation.lastName === "") {
      newErrors.lastName = { error: true, helperText: "Last name is required" }
    }

    if (!personalInformation.dateOfBirth) {
      newErrors.dob = { error: true, helperText: "Date of birth is required" }
    }

    if (
      personalInformation.phoneNumber &&
      !validateMobilePhone(personalInformation.phoneNumber)
    ) {
      newErrors.number = {
        error: true,
        helperText: "Please enter a valid phone number",
      }
    }

    if (!personalInformation.phoneNumber) {
      newErrors.number = {
        error: true,
        helperText: "Mobile phone number is required",
      }
    }

    if (!personalInformation.email) {
      newErrors.email = { error: true, helperText: "Email address is required" }
    }

    if (
      personalInformation.email &&
      !validateEmail(personalInformation.email)
    ) {
      newErrors.email = {
        error: true,
        helperText: "Please enter a valid email address",
      }
    }

    if (personalInformation.isVulnerablePerson && (personalInformation.psrCategory === undefined || personalInformation.psrCategory?.length === 0)) {
      newErrors.psrCategory = {
        error: true,
        helperText: "Please select the reason"
      }
    }

    if (
      personalInformation.psrAuthorisedContactName &&
      !validateName(personalInformation.psrAuthorisedContactName)
    ) {
      newErrors.psrAuthorisedContactName = {
        error: true,
        helperText: "Please enter a valid authorised contact name",
      }
    }

    if (
      personalInformation.psrAuthorisedContactName &&
      !validateMaxLength(personalInformation.psrAuthorisedContactName, 200)
    ) {
      newErrors.psrAuthorisedContactName = {
        error: true,
        helperText: "Authorised contact name can only be up to 200 characters",
      }
    }

    if (
      personalInformation.psrAuthorisedContactEmail &&
      !validateEmail(personalInformation.psrAuthorisedContactEmail)
    ) {
      newErrors.psrAuthorisedContactEmail = {
        error: true,
        helperText: "Please enter a valid authorised contact email",
      }
    }

    if (
      personalInformation.psrAuthorisedContactEmail &&
      !validateMaxLength(personalInformation.psrAuthorisedContactEmail, 320)
    ) {
      newErrors.psrAuthorisedContactEmail = {
        error: true,
        helperText: "Authorised contact email can only be up to 320 characters",
      }
    }

    if (
      personalInformation.psrAuthorisedContactEmail || personalInformation.psrAuthorisedContactName || personalInformation.psrAuthorisedContactDateOfBirth) {
      if (!personalInformation.psrAuthorisedContactName) {
        newErrors.psrAuthorisedContactName = {
          error: true,
          helperText: "Authorised contact name is required if authorised contact email or date of birth are specified",
        }
      }
      else {
        newErrors.psrAuthorisedContactName = {
          error: false,
          helperText: "",
        }
      }

      if (!personalInformation.psrAuthorisedContactEmail) {
        newErrors.psrAuthorisedContactEmail = {
          error: true,
          helperText: "Authorised contact email is required if authorised contact name or date of birth are specified",
        }
      }
      else {
        newErrors.psrAuthorisedContactEmail = {
          error: false,
          helperText: "",
        }
      }

      if (!personalInformation.psrAuthorisedContactDateOfBirth) {
        newErrors.psrAuthorisedContactDateOfBirth = {
          error: true,
          helperText: "Authorised contact date of birth is required if authorised contact name or email are specified",
        }
      }
      else {
        newErrors.psrAuthorisedContactDateOfBirth = {
          error: false,
          helperText: "",
        }
      }
    }
    else {
      newErrors.psrAuthorisedContactName = {
        error: false,
        helperText: "",
      }
      newErrors.psrAuthorisedContactEmail = {
        error: false,
        helperText: "",
      }
      newErrors.psrAuthorisedContactDateOfBirth = {
        error: false,
        helperText: "",
      }
    }

    setValidationErrors(newErrors)
    return newErrors
  }

  const previouslyEnteredContractLength = group && group.quote.contractLength
  let newContractLength = null

  if (tenancyInformation.startDate && tenancyInformation.endDate) {
    const start = new Date(tenancyInformation.startDate);
    const end = new Date(tenancyInformation.endDate);

    const yearDiff = end.getFullYear() - start.getFullYear();
    const monthDiff = end.getMonth() - start.getMonth();

    const totalMonths = yearDiff * 12 + monthDiff;

    newContractLength = Math.round(Math.max(9, Math.min(12, totalMonths)));
}

var lastAvailableDateForResidential = DateTime.local().plus({ days: 30 })
  return (
    <>
      <Centered1200Wrapper>
        <div style={{ padding: "0 20px" }}>
          <PageTitle
            style={{ textAlign: "left" }}
          >
            <div className="quotingTool-pageTitle">Details</div>
          </PageTitle>
          <div
            style={{
              fontWeight: "400",
              color: "#000",
              opacity: "0.54",
              marginBottom: 20,
            }}
            className="quotingTool-pageDescription"
          >
            Enter your details to create your account with Bunch.
          </div>
        </div>
        {primary && (
          <>
            <FormLine>
              <InputSection
                error={validationErrors.tenancyStart.error}
                helperText={validationErrors.tenancyStart.helperText}
                label={`${isHomeownerOrRenter ? "Contract" : "Tenancy"} start`}
                popupText="This is the when your legal contract with Bunch starts"
                type="date"
                disabled={hasAgreedToTerms}
                submitAttempted={submitAttempted}
                inputProps={{
                  min: DateTime.local().toFormat("yyyy-MM-dd"),
                  max: DateTime.local().plus({ months: 6 }).toFormat("yyyy-MM-dd"),
                }}
                value={tenancyInformation.startDate}
                placeholder="YYYY-MM-DD"
                onChange={(e) => {
                  let value = e.target.value;
                  let vErrors = { ...validationErrors, tenancyStart: { ...validationErrors.tenancyStart } };
                
                  // Ensure we validate every character input
                  if (!/^\d{0,4}-?\d{0,2}-?\d{0,2}$/.test(value)) {
                    vErrors.tenancyStart = {
                      error: true,
                      helperText: "Invalid format. Use YYYY-MM-DD.",
                    };
                  } else if (value.length === 10) {
                    // Validate only when full date is entered
                    let d = DateTime.fromFormat(value, "yyyy-MM-dd");
                    let today = DateTime.local();
                    let maxDate = today.plus({ months: 6 });
                
                    if (!d.isValid) {
                      vErrors.tenancyStart = {
                        error: true,
                        helperText: `Please enter a valid ${isHomeownerOrRenter ? "contract" : "tenancy"} start date.`,
                      };
                    } else if (d < today.startOf("day") || d > maxDate) {
                      vErrors.tenancyStart = {
                        error: true,
                        helperText: `${isHomeownerOrRenter ? "Contract" : "Tenancy"} start date must be from today onwards, or up to 6 months in the future.`,
                      };
                    } else {
                      vErrors.tenancyStart = { error: false, helperText: "" };
                    }
                  } else {
                    vErrors.tenancyStart = { error: true, helperText: "Incomplete date. Use YYYY-MM-DD format." };
                  }
                
                  setValidationErrors(vErrors);
                  setTenancyInformation({ startDate: value });
                }}
                
                onBlur={e => {
                  setTenancyInformation({
                    startDate: e.target.value,
                    endDate: DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                      .plus({ months: group.quote.contractLength })
                      .plus({ days: group.quote.contractLength && group.quote.contractLength > 9 ? -1 : 0})
                      .toISODate(),
                  })
                  setPersonalInformation({
                    moveInDate: e.target.value,
                  })
                  let vErrors = {
                    ...validationErrors,
                    tenancyEnd: { error: false, helperText: "" },
                  }
                  setValidationErrors(vErrors)
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {
                (checkMoveInDate(tenancyInformation.startDate)) &&
                <div className={classes.warningMessage}>{moveInDateWarningMessage}</div>
              }
            </FormLine>
          </>
        )}
        {!primary && <FormLine>
          <InputSection
            error={validationErrors.firstName.error}
            helperText={validationErrors.firstName.helperText}
            disabled={hasAgreedToTerms}
            label="First name"
            popupText="We’ll register you with this name"
            value={personalInformation.firstName}
            submitAttempted={submitAttempted}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                firstName: { ...validationErrors.firstName },
              }
              if (e.target.value === "") {
                vErrors.firstName = {
                  error: true,
                  helperText: "First name is required",
                }
              } else if (!validateName(e.target.value)) {
                vErrors.firstName = {
                  error: true,
                  helperText: "Please enter a valid name",
                }
              } else {
                vErrors.firstName = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ firstName: e.target.value })
            }}
          />
        </FormLine>}
        {!primary && <FormLine>
          <InputSection
            error={validationErrors.lastName.error}
            helperText={validationErrors.lastName.helperText}
            disabled={hasAgreedToTerms}
            label="Last name"
            value={personalInformation.lastName}
            submitAttempted={submitAttempted}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                lastName: { ...validationErrors.lastName },
              }
              if (e.target.value === "") {
                vErrors.lastName = {
                  error: true,
                  helperText: "Last name is required",
                }
              } else if (!validateName(e.target.value)) {
                vErrors.lastName = {
                  error: true,
                  helperText: "Please enter a valid name",
                }
              } else {
                vErrors.lastName = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ lastName: e.target.value })
            }}
          />
        </FormLine>}
        <FormLine>
          <InputSection
            name={"dateOfBirth"}
            disabled={hasAgreedToTerms}
            error={validationErrors.dob.error}
            helperText={validationErrors.dob.helperText}
            label="Date of birth"
            popupText="We use this for data security if you phone in to speak to us"
            type="date"
            value={personalInformation.dateOfBirth}
            submitAttempted={submitAttempted}
            placeholder="YYYY-MM-DD"
            inputProps={{
              min: DateTime.fromFormat("1900-01-01", "yyyy-MM-dd").toFormat(
                "yyyy-MM-dd"
              ),
              max: DateTime.local().plus({ years: -16 }).toFormat("yyyy-MM-dd"),
            }}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                dob: { ...validationErrors.dob },
              }
              var cutoffDate = DateTime.fromISO("1900-01-01");

              if (e.target.value === "" || e.target.value === null) {
                vErrors.dob = {
                  error: true,
                  helperText: "Date of birth is required",
                }
              } else if (DateTime.fromISO(e.target.value) >= DateTime.local()) {
                vErrors.dob = {
                  error: true,
                  helperText: "Please enter valid date of birth",
                }
              } else {
                var d = DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                if (!d.isValid) {
                  vErrors.dob = {
                    error: true,
                    helperText:
                      "Please enter valid date of birth in the format DD-MM-YYYY",
                  }
                } else {
                  if (d < cutoffDate) {
                    vErrors.dob = {
                      error: true,
                      helperText:
                        "Please enter birth date later than January 1, 1900.",
                    }
                  } else if (d > DateTime.local().plus({ years: -18 })) {
                    vErrors.dob = {
                      error: true,
                      helperText:
                        "You must be at least 18 years old to use this service.",
                    }
                  } else {
                    vErrors.dob = { error: false, helperText: "" }
                  }
                }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ dateOfBirth: e.target.value })
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormLine>
        {!primary && <FormLine>
          <InputSection
            error={validationErrors.number.error}
            helperText={validationErrors.number.helperText}
            disabled={hasAgreedToTerms}
            label="Mobile phone number"
            popupText="So we can SMS you with details of your contract if needed"
            value={personalInformation.phoneNumber}
            submitAttempted={submitAttempted}
            // type="phone"
            onChange={e => {
              let val = e.target.value
              let vErrors = {
                ...validationErrors,
                number: { ...validationErrors.number },
              }
              if (val === "") {
                vErrors.number = {
                  error: true,
                  helperText: "Mobile phone number is required",
                }
              } else if (!validateMobilePhone(val)) {
                vErrors.number = {
                  error: true,
                  helperText: "Please enter a valid mobile phone number",
                }
              } else {
                vErrors.number = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ phoneNumber: val })
            }}
          />
        </FormLine>}
        {!primary && <FormLine>
          <InputSection
            error={validationErrors.email.error}
            helperText={validationErrors.email.helperText}
            disabled={hasAgreedToTerms}
            label="Email"
            popupText="So we can email you with details of your contract"
            value={personalInformation.email}
            submitAttempted={submitAttempted}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                email: { ...validationErrors.email },
              }
              if (e.target.value === "") {
                vErrors.email = {
                  error: true,
                  helperText: "Email address is required",
                }
              } else if (!validateEmail(e.target.value)) {
                vErrors.email = {
                  error: true,
                  helperText: "Please enter a valid email",
                }
              } else {
                vErrors.email = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ email: e.target.value })
            }}
          />
        </FormLine>}

        <div
          style={{
            display: "flex",
            flexDirection: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: 40,
            padding: "0 20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Checkbox
                color="primary"
                id="agree"
                name="agree"
                disabled={hasAgreedToTerms}
                checked={personalInformation.isVulnerablePerson}
                value={personalInformation.isVulnerablePerson}
                onChange={e => {

                  setPersonalInformation({
                    ...personalInformation,
                    psrCategory: [],
                    psrAuthorisedContactName: "",
                    psrAuthorisedContactEmail: "",
                    psrAuthorisedContactDateOfBirth: "",
                    isVulnerablePerson: !personalInformation.isVulnerablePerson
                  })
                  setValidationErrors({
                    ...validationErrors, psrCategory: {
                      error: false,
                      helperText: "",
                    }
                  })
                }}
                className={classes.checkbox}
              />
              <div
                htmlFor="agree"
                style={{
                  fontSize: "15px",
                  fontFamily: "tenon, sans-serif",
                  color: "#000",
                }}
                className={classes.checkboxLabel}
              >
                Are you registered with the Priority Services Register? Please
                tick here and select the reason from the list below.
              </div>
            </div>
          </div>
        </div>
        {personalInformation.isVulnerablePerson &&
          <>
            <FormLine>
              <DropdownWithMultipleSelection
                label={"Please select the reason from the list below"}
                labels={PSROptions}
                multiple={true}
                setValue={payload => {
                  setPersonalInformation({ ...personalInformation, psrCategory: payload.map((category) => category.label) })
                  if (payload.length > 0) {
                    setValidationErrors({ ...validationErrors, psrCategory: { error: false, helperText: "" } })
                  }
                }}
                error={validationErrors.psrCategory.error}
                helperText={validationErrors.psrCategory.helperText}
                value={personalInformation.psrCategory}
                placeholder={(personalInformation.psrCategory === undefined || personalInformation.psrCategory?.length === 0) && "Select the reason"} />
            </FormLine>
            <FormLine>
              <InputSection
                error={validationErrors.psrAuthorisedContactName.error}
                disabled={hasAgreedToTerms}
                helperText={validationErrors.psrAuthorisedContactName.helperText}
                label="Authorised Contact Name"
                value={personalInformation.psrAuthorisedContactName}
                submitAttempted={submitAttempted}
                onChange={e => {
                  let vErrors = {
                    ...validationErrors,
                    psrAuthorisedContactName: { ...validationErrors.psrAuthorisedContactName },
                  }
                  if (!validateMaxLength(e.target.value, 200)) {
                    vErrors.psrAuthorisedContactName = {
                      error: true,
                      helperText: "Authorised contact name can only be up to 200 characters",
                    }
                  } else if ((personalInformation.psrAuthorisedContactEmail || personalInformation.psrAuthorisedContactDateOfBirth) && !e.target.value) {
                    vErrors.psrAuthorisedContactName = {
                      error: true,
                      helperText: "If an authorised contact is specified, name, email and date of birth are all required",
                    }
                  }
                  else if (e.target.value && !validateName(e.target.value)) {
                    vErrors.psrAuthorisedContactName = {
                      error: true,
                      helperText: "Please enter a valid authorised contact name",
                    }
                  } else {
                    vErrors.psrAuthorisedContactName = { error: false, helperText: "" }
                  }
                  setValidationErrors(vErrors)
                  setPersonalInformation({ psrAuthorisedContactName: e.target.value })
                }}
              />
            </FormLine>
            <FormLine>
              <InputSection
                error={validationErrors.psrAuthorisedContactEmail.error}
                disabled={hasAgreedToTerms}
                helperText={validationErrors.psrAuthorisedContactEmail.helperText}
                label="Authorised Contact Email"
                popupText="The email address of the authorised contact"
                value={personalInformation.psrAuthorisedContactEmail}
                submitAttempted={submitAttempted}
                onChange={e => {
                  let vErrors = {
                    ...validationErrors,
                    psrAuthorisedContactEmail: { ...validationErrors.psrAuthorisedContactEmail },
                  }
                  if (!validateMaxLength(e.target.value, 320)) {
                    vErrors.psrAuthorisedContactEmail = {
                      error: true,
                      helperText: "Authorised contact email can only be up to 320 characters",
                    }

                  }
                  else if ((personalInformation.psrAuthorisedContactName || personalInformation.psrAuthorisedContactDateOfBirth) && !e.target.value) {
                    vErrors.psrAuthorisedContactName = {
                      error: true,
                      helperText: "If an authorised contact is specified, name, email and date of birth are all required",
                    }
                  }
                  else if (e.target.value && !validateEmail(e.target.value)) {
                    vErrors.psrAuthorisedContactEmail = {
                      error: true,
                      helperText: "Please enter a valid authorised contact email",
                    }
                  } else {
                    vErrors.psrAuthorisedContactEmail = { error: false, helperText: "" }
                  }
                  setValidationErrors(vErrors)
                  setPersonalInformation({ psrAuthorisedContactEmail: e.target.value })
                }}
              />
            </FormLine>
            <FormLine>
              <InputSection
                name={"authorisedContactDateOfBirth"}
                error={validationErrors.psrAuthorisedContactDateOfBirth.error}
                helperText={validationErrors.psrAuthorisedContactDateOfBirth.helperText}
                label="Authorised Contact Date of Birth"
                popupText="The date of birth of the authorised contact who can speak on your behalf regarding your Bunch account"
                type="date"
                value={personalInformation.psrAuthorisedContactDateOfBirth}
                submitAttempted={submitAttempted}
                placeholder="YYYY-MM-DD"
                inputProps={{
                  min: DateTime.fromFormat("1900-01-01", "yyyy-MM-dd").toFormat(
                    "yyyy-MM-dd"
                  ),
                  max: DateTime.local().plus({ years: -18 }).toFormat("yyyy-MM-dd"),
                }}
                onChange={e => {
                  let vErrors = {
                    ...validationErrors,
                    psrAuthorisedContactDateOfBirth: { ...validationErrors.psrAuthorisedContactDateOfBirth },
                  }
                  var cutoffDate = DateTime.fromISO("1900-01-01");

                  if (DateTime.fromISO(e.target.value) >= DateTime.local()) {
                    vErrors.psrAuthorisedContactDateOfBirth = {
                      error: true,
                      helperText: "Please enter valid date of birth",
                    }
                  }
                  else if ((personalInformation.psrAuthorisedContactName || personalInformation.psrAuthorisedContactEmail) && !e.target.value) {
                    vErrors.psrAuthorisedContactName = {
                      error: true,
                      helperText: "If an authorised contact is specified, name, email and date of birth are all required",
                    }
                  }
                  else {
                    var d = DateTime.fromFormat(e.target.value, "yyyy-MM-dd")
                    if (!d.isValid) {
                      vErrors.psrAuthorisedContactDateOfBirth = {
                        error: true,
                        helperText:
                          "Please enter valid date of birth in the format DD-MM-YYYY",
                      }
                    }
                    else {
                      if (d < cutoffDate) {
                        vErrors.psrAuthorisedContactDateOfBirth = {
                          error: true,
                          helperText:
                            "Please enter birth date later than January 1, 1900.",
                        }
                      } else if (d > DateTime.local().plus({ years: -18 })) {
                        vErrors.psrAuthorisedContactDateOfBirth = {
                          error: true,
                          helperText:
                            "The authorised contact must be at least 18 years old.",
                        }
                      } else {
                        vErrors.psrAuthorisedContactDateOfBirth = { error: false, helperText: "" }
                      }
                    }
                  }
                  setValidationErrors(vErrors)
                  setPersonalInformation({ psrAuthorisedContactDateOfBirth: e.target.value })
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormLine>
          </>
        }
        <div
          style={{
            marginTop: "20px",
            padding: "0 20px",
          }}
        >
          <Button
            className={clsx(
              "SignUp-Step-02-Details",
              "quotingTool-submitButton"
            )}
            //disabled={(!hasError)}
            fullWidth
            onClick={() => {
              if (submitting) {
                return false
              }

              setSubmitAttempted(true)

              // validate everything exists!

              const newErrors = validateFieldsOnSubmit(
                JSON.parse(JSON.stringify(validationErrors))
              )
              setLocalErrors(null)
              let localErrors = []
              let hasError = false
              Object.values(newErrors).forEach(value => {
                if (value.error) {
                  localErrors.push(value.helperText)
                  hasError = true
                }
              })

              console.log(localErrors);

              if (hasError) {
                setLocalErrors([
                  "You need to correct the issues above before continuing",
                ])
                return false
              }

              setSubmitting(true)
              //setErrors(null)

              if (skipPostPersonalInformation || (hasAgreedToTerms && lead.phoenixId)) {
                onContinue()
              } else {
               
                postPersonalInformation({
                  leadId: lead.id,
                  groupId: group.id,
                  startDate: tenancyInformation.startDate,
                  endDate: tenancyInformation.endDate,
                  isTenancyStartDateEstimate:
                    tenancyInformation.isTenancyStartDateEstimate,
                  isTenancyEndDateEstimate:
                    tenancyInformation.isTenancyEndDateEstimate,
                  address: tenancyInformation.address,
                  dateOfBirth: personalInformation.dateOfBirth,
                  graduationYear: personalInformation.graduationYear
                    ? personalInformation.graduationYear.toString()
                    : "",
                  student: personalInformation.studentBool,
                  moveInDate: personalInformation.moveInDate,
                  moveInDateEstimate: personalInformation.moveInDateEstimate,
                  firstName: personalInformation.firstName,
                  lastName: personalInformation.lastName,
                  phoneNumber: personalInformation.phoneNumber,
                  email: personalInformation.email.toLowerCase().trim(),
                  university: personalInformation.university,
                  isVulnerablePerson: personalInformation.isVulnerablePerson,
                  psrCategory: personalInformation.psrCategory,
                  psrAuthorisedContactName: personalInformation.psrAuthorisedContactName,
                  psrAuthorisedContactEmail: personalInformation.psrAuthorisedContactEmail === "" ? null : personalInformation.psrAuthorisedContactEmail,
                  psrAuthorisedContactDateOfBirth: personalInformation.psrAuthorisedContactDateOfBirth,
                }).then(r => {
                  if (r.isSuccess) {
                    setSubmitting(false)
                        onContinue()
                  } else {
                    setSubmitting(false)
                    setErrors(r.errors)
                  }
                })
            }
            }}
          >
            {submitting ? "Processing..." : "Continue To Contract Overview"}
          </Button>
          <Errors>{localErrors || errors}</Errors>
        </div>
      </Centered1200Wrapper>
    </>
  )
}
