import React from "react"
import CreateIcon from "@material-ui/icons/Create"
import { makeStyles } from "@material-ui/core/styles"

import {
  Marker,
  StepperWrapper,
  MarkerWrapper,
} from "../../../quotingTool/components"

import Stepper from "../../../../components/stepper/Stepper"

const useStyles = makeStyles(theme => ({
  title: {
    color: "#FAFAFA",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
}))

export default function TopBarContainer({
  progress,
  isPrimaryLead,
  isOnlyLead,
  currentStep,
  setCurrentStep,
  queueJump,
  isHomeownerOrRenter,
  goToPage
}) {
  const styles = useStyles()

  const pages = [
    {
      title: "Details",
      editable: true,
    },
    {
      title: "Contract Overview",
      editable: true,
    },
    {
      title: "Payment Details",
    },
    {
      title: isHomeownerOrRenter ? "Invite bill payers" : "Invite Housemates",
      primaryLeadOnly: true,
      hideIfQueueJump: true,
      moreThanOneLead: true,
      editable: true,
    }
  ]

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          maxWidth: 700,
          margin: "0 auto",
        }}
      >
        <Stepper pages={pages.filter(page => isPrimaryLead || !page.primaryLeadOnly)
          .filter(page => !isPrimaryLead || !page.notPrimaryLead)
          .filter(page => isOnlyLead || !page.moreThanOneLead)
          .filter(page => !queueJump || (queueJump && !page.hideIfQueueJump))} currentIndex={progress} goToPage={(index) => goToPage(index)} />
      </div>
    </div>
  )
}
 