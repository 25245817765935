import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import { GlobalStateContext } from "../../context/GlobalContextProvider"

import { getMyTenancies as apiGetMyTenancies } from "../../services/tenantsApiV2"

import PageWrapper from "../../components/PageWrapper"
import AppTitle from "../../components/appTitle/AppTitle"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import Frame from "../../components/frame/Frame"

import * as indexStyles from "./index.module.css"

export default ({ location }) => {
  const context = useContext(GlobalStateContext)

  const [loading, setLoading] = useState(true)
  const [tenancies, storeTenancies] = useState([])
  const [getTenanciesError, setGetTenanciesError] = React.useState(null)

  const getMyTenancies = async () => {
    try {
      setLoading(true)
      const data = await Promise.all([apiGetMyTenancies()])

      if (data[0] && data[0].value) {
        storeTenancies(data[0].value)
      }
    } catch (e) {
      console.log(e)
      setGetTenanciesError(e.message)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    getMyTenancies()
  }, [context.loggedIn])

  return (
    <PageWrapper
      location={location}
      showSidebar={false}
      showLoadingIndicator={loading}
      hideHeader={true}
      hideHeaderBorder={true}
      showPortalHeader={true}
      hideMobileMenuButton={true}
    >
      <div className={indexStyles.index} style={{ fontFamily: "tenon, sans-serif" }}>
        {context.tenantUser && (
          <AppTitle
            title={`Hi ${context.tenantUser.firstName}, welcome to Bunch`}
            description=""//"Choose a contract to continue"
          />
        )}
        {getTenanciesError &&
          getTenanciesError === "Please verify your account to continue" && (
            <VerifyAccountToContinue />
          )}
        {!getTenanciesError && (
          <>
          <Frame>
      <div
        style={{
          backgroundColor: "#FF9800",
          color: "white",
          padding: "15px",
          borderRadius: "5px",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        🚨 Major Changes Coming Soon! 🚨 <br />
        We are rolling out updates to improve your experience. Stay tuned! <br />
        In the meantime, if you need any help, please contact our customer services team.
      </div>
    </Frame>
            {/* {tenancies &&
              tenancies.map(tenancy => (
                <Frame>
                  <div
                    className={indexStyles.contract}
                    style={{ marginBottom: 10 }}
                  >
                    <div
                      className={indexStyles.contractName}
                      style={{ marginBottom: 10 }}
                    >
                      {tenancy.name}
                    </div>
                    <Link
                      to={`/tenants/app/tenancies/${tenancy.id}/overview`}
                      style={{ textDecorationLine: "underline" }}
                    >
                      Manage account
                    </Link>
                  </div>
                </Frame>
              ))} */}

            {tenancies && tenancies.length === 0 && (
              <Frame>
                <div style={{ marginBottom: 20 }}>
                  You do not belong to any households yet.
                </div>
              </Frame>
            )}
          </>
        )}
      </div>
    </PageWrapper>
  )
}
